import React, { useContext } from "react";
import { withRouter, useLocation, Redirect } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBack";
import {
  SidebarWrapper,
  NavLink,
  MenuWrapper,
  Svg,
  LogoutBtn,
  TitleLayout,
  Title,
  ButtonMenu,
} from "./Sidebar.style";
import {
  PRODUCTS,
  CATEGORY,
  ORDERS,
  CUSTOMERS,
  MAKER,
  PRICE_SETTINGS,
  POINT,
  DASHBOARD,
  CAMPAIGN,
  TAG_SETTINGS,
  GIFTS,
  MAKER_LINK,
  VR_LINK,
  INTRO_PRODUCT
} from "../../../settings/constants";
import { AuthContext, Role } from "../../../context/auth";
import {
  ProductIcon,
  SidebarCategoryIcon,
  OrderIcon,
  CustomerIcon,
  CouponIcon,
  LogoutIcon,
  RefundIcon,
  GiftBox,
  SmartPhone,
  CheckMark,
  DashboardIcon,
  CarretDownIcon,
  Accessories,
  Dairy,
  HandBags,
  PencilIcon,
  AlertDotIcon,
  InboxIcon,
} from "../../../components/AllSvgIcon";
import { MenuIconContainer, TopbarRightSide } from "../Topbar/Topbar.style";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useQuery } from "@apollo/react-hooks";
import { GET_PROFILE } from "../../../graphql/query/user.query";
import { IconButton, Button, Divider } from "@material-ui/core";
import numeral from "numeral";
import { version } from "../../Login/Login";
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import { theme } from "../../../theme";
import { PROJECT } from "../../../constant";
import openNotification, { typeNotificaton } from "../../../components/openNotification/openNotification";
// import { version } from "mobile-detect";

export const sidebarMenus = [
  {
    name: "売上集計",
    path: DASHBOARD,
    exact: true,
    roleAccess: [Role.admin, Role.hanbaiten],
    icon: <AssignmentIcon style={{ width: "16px" }} />,
  },
  {
    name: "注文履歴",
    path: ORDERS,
    exact: true,
    roleAccess: [Role.admin, Role.hanbaiten],
    icon: <OrderIcon />,
  },
    {
      name: "ポイント管理",
      path: POINT,
      exact: true,
      roleAccess: [Role.admin],
      icon: <PencilIcon />,
    },
  {
    name: "ユーザ管理",
    path: CUSTOMERS,
    exact: false,
    roleAccess: [Role.admin],
    icon: <CustomerIcon />,
  },
  {
    name: "商品管理",
    path: PRODUCTS,
    exact: false,
    roleAccess: [Role.admin, Role.hanbaiten],
    icon: <ProductIcon />,
  },
  {
    name: "景品管理",
    path: GIFTS,
    exact: false,
    roleAccess: [Role.admin],
    icon: <GiftBox />,
  },
  // {
  //   name: "価格設定",
  //   path: PRICE_SETTINGS,
  //   exact: false,
  //   roleAccess: [Role.admin, Role.hanbaiten],
  //   icon: <CouponIcon />,
  // },

  {
    name: "カテゴリー",
    path: CATEGORY,
    exact: false,
    roleAccess: [Role.admin, Role.hanbaiten],
    icon: <SidebarCategoryIcon />,
  },
  {
    name: "メーカー",
    path: MAKER,
    exact: false,
    roleAccess: [Role.admin, Role.hanbaiten],
    icon: <RefundIcon />,
  },
  {
    name: "キャンペーン",
    path: CAMPAIGN,
    exact: false,
    roleAccess: [Role.admin],
    icon: <Dairy />,
  },
  {
    name: "タグ設定",
    path: TAG_SETTINGS,
    exact: false,
    roleAccess: [Role.admin],
    icon: <DashboardIcon />,
  },
  process.env.REACT_APP_PROJECT === PROJECT.chubu
    ?
  {
    name: "VRリンク集",
    path: VR_LINK,
    exact: false,
    roleAccess: [Role.admin],
    icon: <AlertDotIcon />,
    }
    : null,
  process.env.REACT_APP_PROJECT !== PROJECT.kyushu
    ?
  {
    name: "ﾒｰｶｰﾘﾝｸ集​",
    path: MAKER_LINK,
    exact: false,
    roleAccess: [Role.admin],
    icon: <InboxIcon />,
    }
    : null,
  {
    name: "ﾋﾞﾃﾞｵﾘﾝｸ集",
    path: INTRO_PRODUCT,
    exact: false,
    roleAccess: [Role.admin],
    icon: <SubscriptionsIcon style={{ paddingRight: "5px" }}/>,
  },
];

export default withRouter(function Sidebar({
  refs,
  style,
  onMenuItemClick,
  changeShowSidebar,
}: any) {
  const { signout, role, makeAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  const { data, loading, refetch } = useQuery(GET_PROFILE);

  if (!loading && !data && role === 'hanbaiten') {
    // document.location.reload(true)
    makeAuthenticated(false);
    openNotification(
      typeNotificaton.error,
      `データ取得がエラーされました。\n もう一度ログインしてください。`
    );
    return <Redirect to='/login' />
  }

  return (
    <SidebarWrapper ref={refs} style={style}>
      <MenuWrapper>
        <>
          <TitleLayout>
            <Title>
              管理者画面
              <span
                style={{
                  fontSize: 10,
                  marginTop: "auto"
                }}
              >
                {version.v}
              </span>
            </Title>
            <ButtonMenu onClick={changeShowSidebar}>
              <ArrowBackIosIcon style={{ fontSize: 20 }} />
            </ButtonMenu>
          </TitleLayout>
        </>
        {!loading && role === 'hanbaiten' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'center',
              alignItems: 'center',
              marginBottom: 8,
            }}
          >
            <div style={{ color: theme.colors.primary }}>
              <b>{data?.profile?.username}</b>
            </div>
            <div>
              保有ポイント:
              <span style={{ color: '#c00' }}>
                {numeral(data?.profile?.point).format('0,0.[00]')}
              </span>{' '}
              pts
            </div>
          </div>
        )}
        <Divider />
        {sidebarMenus
          .filter((item) => {
            return item !== null && item.roleAccess.includes(role);
          })
          .map((menu: any, index: number) => (
            <NavLink
              to={menu.path}
              key={index}
              exact={menu.exact}
              activeStyle={{
                color: theme.colors.primary,
                backgroundColor: "#f1f1f1",
              }}
              onClick={onMenuItemClick}
            >
              {menu.icon ? <Svg>{menu.icon}</Svg> : ""}
              {menu.name}
            </NavLink>
          ))
        }
      </MenuWrapper>
      <LogoutBtn
        onClick={() => {
          signout();
        }}
      >
        <Svg>
          <LogoutIcon />
        </Svg>
        ログアウト
      </LogoutBtn>
    </SidebarWrapper>
  );
});
