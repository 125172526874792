import gql from 'graphql-tag'

export const GET_CUSTOMERS = gql`
  query Users(
    $companyId: String
    $branchId: String
    $searchText: String = ""
    $limit: Float = 0
  ) {
    users(
      companyId: $companyId
      branchId: $branchId
      searchText: $searchText
      limit: $limit
    ) {
      id
      pwd
      username
      userType
      nameKanji
      tel
      nameKana
      addresses {
        id
        address
        default
        lastUsed
      }
      sellerId
      sellerId1
      sellerId2
      sellerId3
      mailAddr
      hatTeamCode
      hatTokuCode
    }
  }
`

export const GET_USERS = gql`
  query Users1(
    $companyId: String
    $branchId: String
    $searchText: String
    $userType: UserType
    $limit: Float = 0
  ) {
    users(
      companyId: $companyId
      branchId: $branchId
      searchText: $searchText
      userType: $userType
      limit: $limit
    ) {
      id
      pwd
      username
      userType
      nameKanji
      tel
      nameKana
      point
      addresses {
        id
        address
        default
        lastUsed
      }
      sellerId
      sellerId1
      sellerId2
      sellerId3
      mailAddr
      hatTeamCode
      hatTokuCode
    }
  }
`

export const GET_USERS_HANBAITEN = gql`
  query Users2(
    $companyId: String
    $branchId: String
    $searchText: String = ""
    $userType: UserType
    $limit: Float = 30
  ) {
    users(
      companyId: $companyId
      branchId: $branchId
      searchText: $searchText
      userType: $userType
      limit: $limit
    ) {
      id
      pwd
      username
      userType
      nameKanji
      tel
      nameKana
      point
      sellerId
      sellerId1
      sellerId2
      sellerId3
      hatTeamCode
      hatTokuCode
      addresses {
        id
        address
        default
        lastUsed
      }
      mailAddr
    }
  }
`

export const GET_PROFILE = gql`
  query {
    profile {
      username
      point
    }
  }
`

export const GET_POINTS = gql`
  query($userId: String, $offset: Float = 0, $limit: Float = 100) {
    points(userId: $userId, offset: $offset, limit: $limit) {
      id
      user {
        id
        username
      }
      event
      orderId
      productId
      productName
      gift
      memo
      hatTokuCode
      changeById
      changeByName
      serialCode
      amount
      createdAt
    }
  }
`
export const USERS_WITH_COUNT = gql`
  query(
    $userType: UserType
    $orderBy: UserOrderByInput
    $offset: Float = 0
    $limit: Float = 0
    $analyticsCount: Boolean
    $searchText: String
    $branchId: String
  ) {
    usersAndCount(
      userType: $userType
      orderBy: $orderBy
      offset: $offset
      limit: $limit
      searchText: $searchText
      branchId: $branchId
      alanyticsCount: $analyticsCount
    ) {
      count
      users {
      id
      pwd
      username
      userType
      nameKanji
      tel
      nameKana
      point
      sellerId
      sellerId1
      sellerId2
      sellerId3
      hatTeamCode
      hatTokuCode
      addresses {
        id
        address
        default
        lastUsed
      }
      mailAddr
      }
    }
  }
`