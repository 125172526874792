import React, { useContext, lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  LOGIN,
  PRODUCTS,
  CATEGORY,
  ORDERS,
  SETTINGS,
  CUSTOMERS,
  COUPONS,
  STAFF_MEMBERS,
  SITE_SETTINGS,
  MAKER,
  PRICE_SETTINGS,
  POINT,
  DASHBOARD,
  CAMPAIGN,
  TAG_SETTINGS,
  GIFTS,
  MAKER_LINK,
  VR_LINK,
  INTRO_PRODUCT,
  AUTHORIZER,
} from "./settings/constants";
import AuthProvider, { AuthContext } from "./context/auth";
import { InLineLoader } from "./components/InlineLoader/InlineLoader";
import { sidebarMenus } from "./containers/Layout/Sidebar/Sidebar";

const Products = lazy(() => import("./containers/Products/Products"));
const Gifts = lazy(() => import("./containers/Gifts/Gifts"));
const AdminLayout = lazy(() => import("./containers/Layout/Layout"));
const Category = lazy(() => import("./containers/Category/Category"));
const Maker = lazy(() => import("./containers/Maker/Maker"));
const Orders = lazy(() => import("./containers/Orders/Orders"));
const Point = lazy(() => import("./containers/Point/Point"));
const DashBoard = lazy(() => import("./containers/Dashboard/Dashboard"));
const PriceSettings = lazy(() =>
  import("./containers/PriceSettings/PriceSettings")
);
const Settings = lazy(() => import("./containers/Settings/Settings"));
const SiteSettingForm = lazy(() =>
  import("./containers/SiteSettingForm/SiteSettingForm")
);
const StaffMembers = lazy(() =>
  import("./containers/StaffMembers/StaffMembers")
);
const Customers = lazy(() => import("./containers/Customers/Customers"));
const Coupons = lazy(() => import("./containers/Coupons/Coupons"));
const Campaign = lazy(() => import("./containers/Campaign/Campaign"));
const Login = lazy(() => import("./containers/Login/Login"));
const NotFound = lazy(() => import("./containers/NotFound/NotFound"));
const TagSetting = lazy(() => import("./containers/TagSetting/TagSetting"));
const MakerLink = lazy(() => import("./containers/MakerLink/MakerLink"));
const VRLink = lazy(() => import("./containers/VRLink/VRLink"));
const IntroProduct = lazy(() => import('./containers/IntroduceProduct/Introduce'));
const Authorizer = lazy(() => import('./containers/Authorizer'));

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ path, children, ...rest }) {
  const { isAuthenticated, role } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          path !== "/" ? (
            sidebarMenus.filter((item) => item && item.path === path)[0] &&
              sidebarMenus
                .filter((item) => item && item.path === path)[0]
                .roleAccess.includes(role) ? (
                children
              ) : (
                <NotFound />
              )
          ) : (
              children
            )
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
      }
    />
  );
}

const Routes = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Switch>
          <PrivateRoute exact={true} path={DASHBOARD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <DashBoard />
                {/* <Dashboard /> */}
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Products />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={GIFTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Gifts />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRICE_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PriceSettings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CATEGORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Category />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MAKER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Maker />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Orders />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={POINT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Point />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CUSTOMERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Customers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CAMPAIGN}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Campaign />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={TAG_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <TagSetting />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={VR_LINK}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <VRLink />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MAKER_LINK}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <MakerLink />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={INTRO_PRODUCT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <IntroProduct />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          {/* <PrivateRoute path={COUPONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Coupons />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Settings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={STAFF_MEMBERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <StaffMembers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SITE_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SiteSettingForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute> */}
          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route path={AUTHORIZER}>
            <Authorizer />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </AuthProvider>
  );
};

export default Routes;
