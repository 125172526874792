export const HANBAITEN = 'hanbaiten';
export const KOJITEN = 'kojiten';

export const exportExcelUrlLogin = `${
  process.env.REACT_APP_PROJECT_ENV === 'chubu-stag'
    ? 'http://chubu-test.web-mirai.jp/authorizer?serviceId=login&account='
    : process.env.REACT_APP_PROJECT_ENV === 'chubu-prod'
    ? 'https://chubu.web-mirai.jp/authorizer?serviceId=login&account='
    : process.env.REACT_APP_PROJECT_ENV === 'kansai-stag'
    ? 'http://kansai-test.web-mirai.jp/authorizer?serviceId=login&account='
    : 'http://chubu-test.web-mirai.jp/authorizer?serviceId=login&account='
}`;

// https://chubu.web-mirai.jp/
export const PROJECT = {
  chubu: 'chubu',
  kansai: 'kansai',
  kyushu: 'kyushu',
};

export const defaultCategoryId = '3be47a31-b31f-4706-ad41-f45acd901f59';
