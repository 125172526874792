import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/string/repeat';
import 'isomorphic-unfetch';
// import 'promise-polyfill/src/polyfill';
import '@redq/reuse-modal';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { ApolloProvider } from '@apollo/react-hooks';
import { theme } from './theme';
import Routes from './routes';
import ApolloClient from 'apollo-boost';
import * as serviceWorker from './serviceWorker';
import './theme/global.css';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import openNotification, {
  typeNotificaton,
} from './components/openNotification/openNotification';
const client = new ApolloClient({
  fetchOptions: { fetch },
  uri: process.env.REACT_APP_API_URL,
  request: (operation) => {
    const token = localStorage.getItem('access_token');
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    });
  },
  onError: (props) => {
    if (props.graphQLErrors[0].message === 'Unauthorized') {
      setTimeout(() => {
        window.location.replace('/');
      }, 2000);
      localStorage.removeItem('access_token');
      openNotification(
        typeNotificaton.error,
        `ログイン期限が切られました。\n もう一度ログインしてください。`
      );
    }
  },
});

function App() {
  const engine = new Styletron();

  return (
    <ApolloProvider client={client as any}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <HashRouter>
            <Routes />
          </HashRouter>
        </BaseProvider>
      </StyletronProvider>
    </ApolloProvider>
  );
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
