import React, { useContext, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { AuthContext } from "../../context/auth";
import {
  FormFields,
  FormLabel,
  FormTitle,
  Error,
} from "../../components/FormFields/FormFields";
import { Wrapper, FormWrapper, LogoImage, LogoWrapper } from "./Login.style";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Logoimage from "../../image/PickBazar.png";
import { Text, TextEnvironment } from "../../components/Widgets/RadialBarChart/RadialBarChart.style";
import { register } from "./../../serviceWorker";
import { useForm } from "react-hook-form";
import { styled } from "baseui";

const Form = styled("form", ({ $theme }) => ({}));

export enum version {
  v = "v1.1.0",
}

export default () => {
  let history = useHistory();
  let location = useLocation();
  const { authenticate, isAuthenticated } = useContext(AuthContext);
  if (isAuthenticated) return <Redirect to={{ pathname: "/" }} />;
  const [isLoading, setIsLoading] = useState(false);

  let { from } = (location.state as any) || { from: { pathname: "/" } };

  let login = ({ username, password }) => {
    setIsLoading(true);
    authenticate({ username, password }, (error?: string) => {
      history.replace(from);
      setIsLoading(false);
    });
  };
  const { register, handleSubmit, errors } = useForm();
  React.useEffect(() => {
    register({ name: "username" });
    register({ name: "password" });
  }, [register]);
  return (
    <Wrapper>
      <FormWrapper>
        <Form onSubmit={handleSubmit(login)}>
          <FormFields>
            <LogoWrapper>
              <Text>
                {process.env.REACT_APP_SITE_TITLE}管理者ページ
                <span style={{ fontSize: 10 }}>{version.v}</span>
              </Text>
              {/* <LogoImage src={Logoimage} alt="pickbazar-admin" /> */}
            </LogoWrapper>
            <FormTitle>ログイン</FormTitle>
          </FormFields>

          <FormFields>
            <FormLabel>ユーザID</FormLabel>
            <Input
              name="username"
              placeholder="ユーザID"
              inputRef={register({ required: true, maxLength: 200 })}
            />
            {errors.username && errors.username.type === "required" && (
              <Error>このフィールドが必須な項目です。</Error>
            )}
            {errors.username && errors.username.type === "maxLength" && (
              <Error>このフィールドは、最大200文字です。</Error>
            )}
          </FormFields>
          <FormFields>
            <FormLabel>パスワード</FormLabel>
            <Input
              name="password"
              placeholder="パスワード"
              inputRef={register({ required: true, maxLength: 200 })}
              type="password"
            />
            {errors.password && errors.password.type === "required" && (
              <Error>このフィールドが必須な項目です。</Error>
            )}
            {errors.password && errors.password.type === "maxLength" && (
              <Error>このフィールドは、最大200文字です。</Error>
            )}
          </FormFields>
          <Button
            type="submit"
            isLoading={isLoading}
            // disabled={isSubmitting}
            overrides={{
              BaseButton: {
                style: ({ $theme }) => ({
                  width: "100%",
                  marginLeft: "auto",
                  borderTopLeftRadius: "3px",
                  borderTopRightRadius: "3px",
                  borderBottomLeftRadius: "3px",
                  borderBottomRightRadius: "3px",
                }),
              },
            }}
          >
            ログインする
          </Button>
                    <TextEnvironment>
            {process.env.REACT_APP_ENVIRONMENT}
          </TextEnvironment>
        </Form>
      </FormWrapper>
    </Wrapper>
  );
};
