import React from "react";
import { useMutation, useApolloClient, useQuery } from "@apollo/react-hooks";
import { LOGIN } from "../graphql/mutation/login.mutation";
import jwt from "jsonwebtoken";
import { useDispatch } from "react-redux";
import { setUserLogin, setUserLoginId } from "../redux/common";
import openNotification, {
  typeNotificaton,
} from "../components/openNotification/openNotification";
import { GET_PROFILE } from "../graphql/query/user.query";
import Cookies from "js-cookie";

type AuthProps = {
  isAuthenticated: boolean;
  role: string;
  authenticate: Function;
  signout: Function;
  makeAuthenticated: Function;
};

export const AuthContext = React.createContext({} as AuthProps);
export const Role = {
  admin: "admin",
  hanbaiten: "hanbaiten",
};

const ValidToken = () => {
  const token = localStorage.getItem("access_token");
  // JWT decode & check token validity & expiration.
  if (token) {
    let auth = jwt.decode(token);
    let timenow = new Date().getTime();
    if (timenow < auth.exp * 1000) {
      return { isValid: true, role: auth.role };
    }
  }
  return {
    isValid: false,
    role: null,
  };
};

const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = React.useState(
    ValidToken().isValid
  );
  const [role, setRole] = React.useState<any>(ValidToken().role);

  const dispatch = useDispatch();

  const client = useApolloClient();

  const [login, { data, loading }] = useMutation(LOGIN, {
    onError: (error) => {
      openNotification(typeNotificaton.error, error.toString());
    },
    onCompleted: (data) => {
      if (
        data.login.userType !== "admin" &&
        data.login.userType !== "hanbaiten"
      ) {
        openNotification(
          typeNotificaton.error,
          "無効なユーザー名またはパスワード"
        );
        return;
      }
      client.cache.reset();
      makeAuthenticated(true);
      if (data.login.userType === "admin") setRole(Role.admin);
      else setRole(Role.hanbaiten);
      localStorage.setItem("access_token", data.login.access_token);
      Cookies.set("access_token", data.login.access_token, {
        domain: ".web-mirai.jp",
      });

      let iExpiresTime = parseInt(data.login.expiresTime);
      let timenow = new Date().getTime();

      // setTimeout(() => {
      //   localStorage.removeItem("access_token");
      //   makeAuthenticated(false);
      // }, iExpiresTime - timenow);
    },
  });

  function authenticate({ username, password }, cb) {
    login({ variables: { username, password } })
      .then((data) => {
        localStorage.setItem("userLoginId", data?.data.login.id);
        localStorage.setItem("userNameLogin", data?.data.login.username);
        const userLoginId = localStorage.getItem("userLoginId");
        dispatch(setUserLoginId(userLoginId));
        cb("無効なユーザー名またはパスワード");
      })
      .catch(({ err }) => {
        cb(err);
      });
  }

  function signout(cb) {
    makeAuthenticated(false);
    setRole(null);
    localStorage.removeItem("access_token");
    localStorage.removeItem("userLoginId");
    setTimeout(cb, 100);
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        makeAuthenticated,
        signout,
        role,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
